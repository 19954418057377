import { PolymorphicPropsWithChildren } from '@/typings';
import { CircularLoading } from '@/ui-component/loading/Loader';
import { Box, Stack } from '@mui/material';
import React, { ElementType } from 'react';
import { isMobile, } from 'react-device-detect';

interface DrawerContainerProps {
  minWidth?: number | string;
  maxWidth?: number | string;
  width?: string | number;
  loading?: boolean;
}

export default function DrawerContainer<C extends ElementType>(props: PolymorphicPropsWithChildren<C> & DrawerContainerProps) {
  const {
    children,
    minWidth = 500,
    maxWidth = 700,
    width = '100%',
    loading = false,
    as,
    ...rest
  } = props;

  const _minWidth = isMobile ? '100vw' : minWidth;
  const _maxWidth = isMobile ? '100vw' : maxWidth;
  const Component = as || 'div';

  return (
    <>
      {!!loading && (
        <Box
          height='100vh'
          minWidth={_minWidth}
          maxWidth={_maxWidth}
          width={width}
        >
          <CircularLoading center />
        </Box>
      )}
      {!loading && (
        <Box
          component={Component}
          {...rest}
        >
          <Stack
            direction='column'
            height='100vh'
            minWidth={_minWidth}
            maxWidth={_maxWidth}
            width={width}
          >
            {children}
          </Stack>
        </Box>
      )}
    </>
  )
}
