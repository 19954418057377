import client from "@/api/client";
import AppSettings from "@/config/main";
import { Credential } from '@/models/Credential';
import { PaginationRequestParams, PaginationResult, RequestCriteria } from "@/typings";
import { generatePaginationQueryParams } from "@/utils/pagination";
import queryClient from "@/utils/query-client";
import { delayMs } from "@/utils/timer";
import { useMutation, useQuery, UseQueryOptions } from "react-query";

export enum CredentialRequests {
  all = 'credential.all',
  pagination = 'credential.pagination',
  detail = 'credential.detail'
}

export const useLoadAllCredentials = ({ filters, relations }: RequestCriteria) => {
  return useQuery<Credential[], Error>(CredentialRequests.all, async () => {
    const { data } = await client.get("/credentials", {
      params: {
        filters,
        relations: !!relations ? relations.join(',') : undefined
      }
    });
    await delayMs(AppSettings.defaultRequestDelay);
    return data.map(Credential.load)
  });
}

export const useCredentialPagination = (paginationParams: PaginationRequestParams<Credential>) => {
  return useQuery<PaginationResult<Credential>, Error>(CredentialRequests.pagination, async () => {
    const result = await client.get("/credentials-pagination", {
      params: generatePaginationQueryParams(paginationParams)
    });
    await delayMs(AppSettings.defaultRequestDelay);
    return {
      ...result.data,
      data: result.data.data.map(Credential.load)
    }
  });
}

export const useLoadCredentialDetail = (id: string) => {
  return useQuery<Credential, Error>(CredentialRequests.detail, async () => {
    const { data } = await client.get(`/credentials/${id}`);
    await delayMs(AppSettings.defaultRequestDelay);
    return Credential.load(data)
  });
}

export const useCreateCredentialMutation = () => {
  return useMutation({
    async mutationFn(credential: Credential) {
      console.log(credential)
      const { data } = await client.post('/credentials', credential);
      return data
    },
    onSuccess() {
      queryClient.invalidateQueries(CredentialRequests.all)
      queryClient.invalidateQueries(CredentialRequests.pagination)
    },
  });
}

export const useUpdateCredentialMutation = () => {
  return useMutation({
    async mutationFn({ id, data }: any) {
      const result = await client.put(`/credentials/${id}`, data);
      return result
    },
    onSuccess() {
      queryClient.invalidateQueries(CredentialRequests.all)
      queryClient.invalidateQueries(CredentialRequests.pagination)
    }
  });
}

export const useDeleteCredentialMutation = () => {
  return useMutation({
    async mutationFn(id: string) {
      const result = await client.delete(`/credentials/${id}`);
      return result
    },
    onSuccess() {
      queryClient.invalidateQueries(CredentialRequests.all)
      queryClient.invalidateQueries(CredentialRequests.pagination)
    }
  });
}