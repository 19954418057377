import { useContextualDrawer } from "@/context/contextual-drawer.context";
import { PolymorphicProps } from "@/typings";
import { Section } from "@/ui-component/section/Section";
import { Box, useTheme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React, { ElementType, useEffect, useRef, useState } from "react";

const levelMarginMap = {
  1: 0,
  2: 10,
  3: 16,
  4: 20,
  5: 22,
};

type DrawerLayerProps = {
  content: (openFn: () => void) => React.ReactNode;
  onClose?: (returnedValue: any) => void;
}
function DrawerLayer<C extends ElementType>(props: DrawerLayerProps & PolymorphicProps<C>) {
  const { content, onClose, title, as, ...rest } = props;

  const { palette } = useTheme();
  const { levelCount, addLevel, removeLevel } = useContextualDrawer();
  const [isOpen, setIsOpen] = useState(false)
  const [marginRight, setMarginRight] = useState(0);

  const drawerName = `drawer-layer-${levelCount}`;
  const ownLevel = useRef(levelCount);

  useEffect(() => {
    if (isOpen) {
      const diff = levelCount - ownLevel.current;
      let newMr: number;
      if (diff > 5) {
        newMr = levelMarginMap[5];
      } else {
        newMr = levelMarginMap[diff];
      }
      setMarginRight(newMr);
    }
  }, [levelCount, isOpen])

  const openDrawer = () => {
    addLevel()
    setIsOpen(true)
  }

  const handleRootClose = () => {
    removeLevel()
    setIsOpen(false);
  }

  const handleClose = () => {
    handleRootClose()
    !!onClose && onClose(null);
  }

  const handleContentClose = (value?: any) => {
    handleRootClose();
    !!onClose && onClose(value);
  }

  const Component = as || 'div';
  return (
    <>
      {content(openDrawer)}
      <Drawer
        variant="temporary"
        anchor="right"
        open={isOpen}
        transitionDuration={300}
        onClose={handleClose}
      >
        <Box
          bgcolor={palette.background.paper}
          mr={marginRight}
          height='100vh'
        >
          {isOpen && (
            <Component
              {...rest}
              title='polymorphic-content'
              closeDrawer={handleContentClose}
            />
          )}
        </Box>
      </Drawer >
    </>
  )
}

DrawerLayer.displayName = 'DrawerLayer';
export default DrawerLayer