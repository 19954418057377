import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

interface IDrawerContextual {
  levelCount: number,
  addLevel: () => void,
  removeLevel: () => void,
}

const ContextualDrawerContext = React.createContext<IDrawerContextual | null>(null);

export function ContextualDrawerProvider({ children }: PropsWithChildren) {
  const [levelCount, setLevelCount] = useState<number>(0);

  const addLevel = useCallback(() => {
    setLevelCount(oldLevel => oldLevel + 1)
  }, [])

  const removeLevel = useCallback(() => {
    setLevelCount(oldLevel => oldLevel - 1)
  }, [])

  const value = useMemo(() => ({
    levelCount,
    addLevel,
    removeLevel,
  }), [
    levelCount,
    addLevel,
    removeLevel,
  ])

  return <ContextualDrawerContext.Provider
    value={value}
  >
    {children}
  </ContextualDrawerContext.Provider>
}

export function useContextualDrawer() {
  if (!ContextualDrawerContext) {
    throw new Error('context not found')
  }
  return useContext(ContextualDrawerContext);
}