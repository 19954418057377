import { DrawerContainer, DrawerLayerContent, DrawerLayerFooter, DrawerLayerHeader, NodeAvatar } from '@/components';
import { PropsWithCloseDrawer } from '@/components/drawer-layer/drawer-layer.types';
import { useCreateCredentialMutation, useUpdateCredentialMutation } from '@/hooks/credential.hooks';
import { Credential } from '@/models/Credential';
import { CredentialLibrary } from '@/models/CredentialComponent';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AutocompleteElement, FormContainer, useFormContext } from 'react-hook-form-mui';
import { useCredentialsComponentsLoadAll } from '../../hooks/credential-library.hooks';
import CredentialComponentDynamicFields from './CredentialComponentDynamicFields';
import UnsafeHtml from '@/ui-component/unsafe-html';
import { useTranslation } from 'react-i18next';
import { FilterProvider } from '@/typings';

type CredentialFormProps = {
  credentialModel?: Credential
  credentialLibraryFilter?: FilterProvider<CredentialLibrary>
}

function CredentialForm(props: PropsWithCloseDrawer<CredentialFormProps>) {
  const { t } = useTranslation()
  const { closeDrawer, credentialModel, credentialLibraryFilter } = props
  const filters = !!credentialLibraryFilter ? JSON.stringify(credentialLibraryFilter) : undefined;

  const { data: libraries } = useCredentialsComponentsLoadAll({ filters });
  const createCredentialMutation = useCreateCredentialMutation();
  const updateCredentialMutation = useUpdateCredentialMutation();
  const model = useMemo<Credential>(() => (credentialModel || {}) as Credential, [credentialModel]);
  const [component, setComponent] = useState<CredentialLibrary>(model.credentialLibrary || null);

  const handleOnSubmitClick = async (fields: Record<string, any>, event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.target.id !== 'credential-form') {
      return;
    }
    const instance = Credential.load(fields);
    if (!!instance.id) {
      const result = await updateCredentialMutation.mutateAsync({ id: instance.id, data: instance });
      !!closeDrawer && closeDrawer(result);
    } else {
      const result = await createCredentialMutation.mutateAsync(instance);
      !!closeDrawer && closeDrawer(result);
    }
  }

  const handleOnClose = () => {
    closeDrawer();
  }

  return (
    <DrawerContainer maxWidth={500}
      as={FormContainer}
      defaultValues={credentialModel}
      onSuccess={handleOnSubmitClick}
      progressive={false}
      FormProps={{
        id: 'credential-form',
      }}
    >
      <DrawerLayerHeader
        title={!model.id ? t('credentials.form.create_new_credential') : t('credentials.form.update_credential')}
        onClose={handleOnClose}
      />
      <DrawerLayerContent>
        {
          !component && (
            <AutocompleteElement
              name='credentialLibrary'
              label={t('credentials.form.library_component')}
              required
              options={libraries || []}
              autocompleteProps={{
                getOptionKey: (opt) => `option-${opt.id}`,
                getOptionLabel: (opt) => opt.library_name,
                onChange: (_e, value) => setComponent(value),
                renderOption: (props, option) => (
                  <Box component='li' {...props}>
                    {!!option.icon && (
                      <Avatar
                        src={option.iconUrl}
                        variant='circular'
                        sx={{
                          p: 1, width: 40,
                          height: 40,
                        }}
                      />
                    )}
                    <Stack direction='column' gap={1} alignItems={'flex-start'}>
                      <Typography variant='h5' sx={{ fontWeight: '700' }}>{option.library_name}</Typography>
                      <Typography variant='body1'>
                        {!!option.description && (
                          <UnsafeHtml as='div'>
                            {option.description}
                          </UnsafeHtml>
                        )}
                      </Typography>
                    </Stack>
                  </Box>
                )
              }}
            />
          )
        }
        {!!component && (
          <CredentialComponentDynamicFields
            component={component}
            onChangeClick={() => {
              setComponent(null)
            }}
          />
        )}
      </DrawerLayerContent>
      <DrawerLayerFooter center>
        <Button
          sx={{ width: 300 }}
          variant='contained'
          color='primary'
          type='submit'
        >
          {t('common:save')}
        </Button>
      </DrawerLayerFooter>
    </DrawerContainer>
  )
}

export default CredentialForm